<template>
  <div :class="['input-field', rootClasses]">
    <label v-if="label" class="input-field__label"> {{ label }} <span v-if="required">*</span> </label>
    <div class="input-field__content">
      <input
        :value="value"
        type="text"
        class="input-field__content-input"
        :placeholder="placeholder"
        @input="handleInput"
        @blur="$emit('inputBlur')"
      />
      <div v-show="hasValue" class="input-field__content-clear" @click="handleClearClick">
        <SvgoCloseIcon class="input-field__content-clear--icon" />
      </div>
    </div>
    <span v-if="error" class="input-field__error">{{ error }}</span>
  </div>
</template>

<script setup lang="ts">
import type { IInputFieldEmits, IInputFieldProps } from './InputField.types';

const props = defineProps<IInputFieldProps>();
const emits = defineEmits<IInputFieldEmits>();

const hasValue = computed(() => props.value?.length);

const rootClasses = computed(() => ({
  'input-field--disabled': props.isDisabled,
  'input-field--error': props.error,
  'input-field--success': props.isSucceed,
}));

const handleInput = (e: Event) => {
  if (!e.target) return;
  const target = e.target as HTMLInputElement;

  emits('rawInput', target);
  emits('update:value', target.value);
};

const handleClearClick = () => {
  emits('update:value', '');
};
</script>

<style scoped lang="scss" src="./InputField.scss" />
